.mainContainer {
  height: calc(100% - (80px));
  @apply container px-3 py-2 lg:px-6 lg:py-8 mx-auto w-full grid grid-cols-12 gap-6;

  &.withFooter {
    height: calc(100% - (80px + 120px));
  }
}

.mainColumn {
  @apply col-span-12;
}
